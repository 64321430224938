
import {Component, Mixins, Prop, Ref} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormWrapper from "@/components/FormWrapper.vue";
import {GelirGiderTurEntity} from "@/entity/GelirGiderTurEntity";
import PersonelTipiPicker from "@/components/pickers/PersonelTipiPicker.vue";

@Component({
  components: {PersonelTipiPicker, FormWrapper}
})
export default class GelirGiderTurForm extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) gelirMi!: boolean;
  @Ref('form') formWrapper!: FormWrapper;

  localValue!: GelirGiderTurEntity;

  save() {
    this.formWrapper.onFly = true;
    if (this.localValue.id) {
      this.$http.put('/api/v1/kasa-hareketi-turu/' + this.localValue.id, this.localValue).then(() => (this.$emit('onSuccess'))).finally(() => (this.formWrapper.onFly = false));
    } else {
      this.localValue.gelir_mi = this.gelirMi;
      this.$http.post('/api/v1/kasa-hareketi-turu', this.localValue).then(() => (this.$emit('onSuccess'))).finally(() => (this.formWrapper.onFly = false));
    }
  }
}
