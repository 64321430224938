

import {Component, Mixins, Prop, Watch} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import GelirGiderTurForm from "@/components/forms/GelirGiderTurForm.vue";

@Component({
  components: {GelirGiderTurForm, DeleteDialog, FormDialog}
})
export default class GelirGiderTurList extends Mixins(SimpleInputMixin) {
  @Prop({required: true}) gelirMi!: boolean;

  items = [];
  headers = [
    {text: 'İsim', value: 'isim'},
    {text: 'İşlemler', value: 'actions', width: 100, align: 'center', sortable: false}
  ]

  onClose() {
    this.$emit('onClose');
  }
  mounted() {
    this.load();
  }

  load() {
    this.$http.get('/api/v1/kasa-hareketi-turu/filter', {
      params: {
        gelir: (this.gelirMi ? 1 : 0)
      }
    }).then((respose) => (this.items = respose.data));
  }
}
