
import {Component, Mixins, Prop} from "vue-property-decorator";
import FormWrapper from "@/components/FormWrapper.vue";
import {GelirGiderEntity} from "@/entity/GelirGiderEntity";
import GelirGiderTurPicker from "@/components/pickers/GelirGiderTurPicker.vue";
import Tutar from "@/components/inputs/Tutar.vue";
import ParaBirimiPicker from "@/components/pickers/ParaBirimiPicker.vue";
import Kur from "@/components/inputs/Kur.vue";
import Dates from "@/components/inputs/Dates.vue";
import ObjectInputMixin from "@/mixins/ObjectInputMixin.vue";

@Component({
  components: {Dates, Kur, ParaBirimiPicker, Tutar, GelirGiderTurPicker, FormWrapper}
})
export default class GelirGiderForm extends Mixins(ObjectInputMixin) {
  @Prop() gelirMi!: boolean | null;

  localValue!: GelirGiderEntity;

  save() {
    if (this.localValue.id)
      this.$http.put('/api/v1/kasa-hareketi/' + this.localValue.id, this.localValue).then(() => (this.$emit('onSuccess')));
    else {
      if (this.gelirMi != null)
        this.localValue.gelir_mi = this.gelirMi;
      this.localValue.hesaba_eklensin_mi = true;
      this.localValue.kasa_id=1
      this.$http.post('/api/v1/kasa-hareketi', this.localValue).then(() => (this.$emit('onSuccess')));
    }
  }
}
