
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import tr from 'apexcharts/dist/locales/tr.json'
import FormDialog from "@/components/dialogs/FormDialog.vue";
import GelirGiderForm from "@/components/forms/GelirGiderForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {GelirGiderEntity} from "@/entity/GelirGiderEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";

@Component({
  components: {DeleteDialog, GelirGiderForm, FormDialog}
})
export default class GelirGiderInfo extends Vue {
  @Prop() items!: Array<GelirGiderEntity>;
  @Prop() singleParaBirimi!: boolean;
  @Ref('gelirPastaGrafikGelirGider') gelirPastaGrafik!: ApexCharts;
  @Ref('giderPastaGrafikGelirGider') giderPastaGrafik!: ApexCharts;

  grafikTuru: string = 'bar';

  headers = [
    {text: 'Tarih', value: 'tarih', class: 'my-header-style'},
    {text: 'Tür', value: 'tur', class: 'my-header-style'},
    {text: 'Açıklama', value: 'aciklama', class: 'my-header-style'},
    {text: 'Tutar', value: 'tutar', align:'start', class: 'my-header-style'},
    {text: 'İşlemler', value: 'actions', align:'center' , sortable: false, class: 'header-islemler-style'}
  ]

  grafikItems = [
    {text: 'Sütun', value: 'bar'},
    {text: 'Çizgi', value: 'line'},
    {text: 'Alan', value: 'area'}
  ]

  get gelirItems(){
    return this.items.filter((item:any) => item.gelir_mi === true);
  }

  get giderItems(){
    return this.items.filter((item:any) => item.gelir_mi === false);
  }

  mounted() {
    this.grafikTuru = 'bar';
  }

  get series() {
    let datas: Array<{ name: string|null, para_birimi_id: number, data: Array<Array<any>> }> = [];
    this.items.forEach((item) => {
      if (item.hesaba_eklensin_mi) {
        let paraBirimiItems = datas.find(data => data.para_birimi_id == item.para_birimi_id);
        if (paraBirimiItems) {
          let paraBirimiItemData = paraBirimiItems.data.find(data => data[0] == new Date((item.tarih as any).substr(0, 10)).getTime());
          if (paraBirimiItemData) {
            if (item.gelir_mi)
              paraBirimiItemData[1] += Number(item.mutlak_tutar);
            else
              paraBirimiItemData[1] -= Number(item.mutlak_tutar);
          } else
            paraBirimiItems.data.push([new Date((item.tarih as any).substr(0, 10)).getTime(), item.gelir_mi ? Number(item.mutlak_tutar) : (Number(item.mutlak_tutar) * (-1))]);
        } else {
          datas.push({
            name: item.para_birimi.isim,
            para_birimi_id: item.para_birimi_id,
            data: [[new Date((item.tarih as any).substr(0, 10)).getTime(), item.gelir_mi ? Number(item.tutar) : (Number(item.tutar) * (-1))]]
          });
        }
      }
    });
    datas.forEach((turler) => {
      turler.data.forEach((veri) => {
        veri[1] = veri[1].toFixed(2);
      })
    });
    return datas;
  }

  get singleParaBirimiSeries() {
    let datas: Array<{ name: string, data: Array<Array<any>> }> = [];
    datas.push({name: 'Gelir', data: []});
    datas.push({name: 'Gider', data: []});
    datas.push({name: 'Net', data: []});
    this.items.forEach((item) => {
      if (item.hesaba_eklensin_mi) {
        let gelirGiderItems:any = datas.find(data => data.name == (item.gelir_mi ? 'Gelir' : 'Gider'));
        let gelirGiderItem = gelirGiderItems.data.find((data:any) => data[0] == new Date((item.tarih as any).substr(0, 10)).getTime());
        let netItems = datas.find(data => data.name == 'Net')?.data.find(data => data[0] == new Date((item.tarih as any).substr(0, 10)).getTime());
        if (gelirGiderItem)
          gelirGiderItem[1] += Number(item.mutlak_tutar);
        else
          gelirGiderItems.data.push([new Date((item.tarih as any).substr(0, 10)).getTime(), Number(item.mutlak_tutar)]);
        if (netItems) {
          if (item.gelir_mi)
            netItems[1] += Number(item.mutlak_tutar);
          else
            netItems[1] -= Number(item.mutlak_tutar);
        } else
          datas[2].data.push([new Date((item.tarih as any).substr(0, 10)).getTime(), item.gelir_mi ? Number(item.mutlak_tutar) : (Number(item.mutlak_tutar) * (-1))])
      }
    });
    datas.forEach((turler) => {
      turler.data.forEach((veri) => {
        veri[1] = veri[1].toFixed(2);
      })
    });
    return datas;
  }

  get yAxisTitle() {
    return this.singleParaBirimi ? 'Tutar' : 'Net Tutar'
  }

  chartOptions = {
    chart: {
      defaultLocale: "tr",
      locales: [tr],
      type: this.grafikTuru
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 3,
    },
    xaxis: {
      type: 'datetime',
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
          return this.$helper.newTutarStr(val)
        }
      }
    }
  }

  get gelirDonutSeries() {
    let gelirler = this.items.filter(item => item.gelir_mi);
    let turGelirleri: Array<{ kasa_hareketi_turu: string, kasa_hareketi_turu_id: number, tutarTlToplam: number }> = [];
    gelirler.forEach(gelir => {
      let turunGeliri = turGelirleri.find((item) => item.kasa_hareketi_turu_id == gelir.kasa_hareketi_turu_id);
      let gelirTutari = gelir.para_birimi_id != ParaBirimi.TRY ? (Number(gelir.tutar) * Number(gelir.kur)) : Number(gelir.tutar)
      if (turunGeliri)
        turunGeliri.tutarTlToplam += gelirTutari;
      else
        turGelirleri.push({
          kasa_hareketi_turu: gelir.kasa_hareketi_turu.isim,
          kasa_hareketi_turu_id: gelir.kasa_hareketi_turu_id,
          tutarTlToplam: gelirTutari
        });
    });
    let labels: Array<string> = [];
    let values: Array<number> = [];
    turGelirleri.forEach(v => {
      labels.push(v.kasa_hareketi_turu);
      values.push(v.tutarTlToplam);
    })
    this.$nextTick(() => {
      this.gelirPastaGrafik.updateOptions({
        labels: labels
      });
    });
    return values;
  }

  get giderDonutSeries() {
    let giderler = this.items.filter(item => !item.gelir_mi);
    let turGiderleri: Array<{ kasa_hareketi_turu: string, kasa_hareketi_turu_id: number, tutarTlToplam: number }> = [];
    giderler.forEach(gider => {
      let turunGideri = turGiderleri.find((item) => item.kasa_hareketi_turu_id == gider.kasa_hareketi_turu_id);
      let giderTutari = gider.para_birimi_id != ParaBirimi.TRY ? (Number(gider.mutlak_tutar) * Number(gider.kur)) : Number(gider.mutlak_tutar)
      if (turunGideri)
        turunGideri.tutarTlToplam += giderTutari;
      else
        turGiderleri.push({
          kasa_hareketi_turu: gider.kasa_hareketi_turu.isim,
          kasa_hareketi_turu_id: gider.kasa_hareketi_turu_id,
          tutarTlToplam: Number(gider.mutlak_tutar)
        });
    });
    let labels: Array<string> = [];
    let values: Array<number> = [];
    turGiderleri.forEach(v => {
      labels.push(v.kasa_hareketi_turu);
      values.push(v.tutarTlToplam);
    })
    this.$nextTick(() => {
      this.giderPastaGrafik.updateOptions({
        labels: labels
      })
    })
    return values;
  }

  defaultDonutChartOptions = {
    title: {
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      }
    },
    chart: {
      defaultLocale: "tr",
      locales: [tr],
      type: 'donut',
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Toplam',
              formatter: (val:any) => {
                let toplam = 0;
                val.config.series.forEach((v:number) => toplam += v);
                return this.$helper.tutarStr(toplam)
              },
              fontWeight: 'bold',
              color: 'grey'
            },
            value: {
              formatter: (val:number) => (this.$helper.tutarStr(val))
            }
          }
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
    },
    dataLabels: {
      formatter(val:number) {
        return val.toFixed(1) + '%'
      }
    },
    tooltip: {
      enabled: false
    }
  }

  gelirDonutChartOptions = {
    labels: [],
    title: {
      ...this.defaultDonutChartOptions.title,
      title: 'Gelir'
    },
    ...this.defaultDonutChartOptions
  }

  giderDonutChartOptions = {
    labels: [],
    title: {
      ...this.defaultDonutChartOptions.title,
      title: 'Gider'
    },
    ...this.defaultDonutChartOptions
  }

  load() {
    this.$emit('load');
  }

  hesabaEklemeDurumuChange(item: GelirGiderEntity) {
    item.hesaba_eklensin_mi = !item.hesaba_eklensin_mi;
    this.$http.put('/api/v1/kasa-hareketi/' + item.id, item);
  }
}
