
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import tr from 'apexcharts/dist/locales/tr.json'
import {GelirGiderEntity} from "@/entity/GelirGiderEntity";
import {ParaBirimi} from "@/enum/ParaBirimi";
import GelirGiderForm from "@/components/forms/GelirGiderForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import GelirGiderTurForm from "@/components/forms/GelirGiderTurForm.vue";
import GelirGiderTurList from "@/components/lists/GelirGiderTurList.vue";

@Component({
  components: {GelirGiderTurList, GelirGiderTurForm, DeleteDialog, GelirGiderForm, FormDialog}
})
export default class GelirInfo extends Vue {
  @Prop() items!: Array<GelirGiderEntity>;
  @Ref('pastaGrafikGelir') pastaGrafik!: ApexCharts;

  grafikTuru: string = 'bar';
  excelHeaders: { [headerName: string]: string  } = {};

  raporAdi: string = "Gelir Tablosu";

  headers = [
    {text: 'Tarih', value: 'tarih'},
    {text: 'Tür', value: 'kasa_hareketi_turu.isim'},
    {text: 'Açıklama', value: 'aciklama'},
    {text: 'Tutar', value: 'tutar'},
    {text: 'İşlemler', value: 'actions', align: 'end', sortable: false}
  ];
  @Watch('items')
  changeItems(){
    this.headers.forEach((header) => {
      if (header.value !== 'actions') {
        this.excelHeaders[header.text] = header.value;
      }
    });
  }
  grafikItems = [
    {text: 'Sütun', value: 'bar'},
    {text: 'Çizgi', value: 'line'},
    {text: 'Alan', value: 'area'}
  ];

  chartOptions = {
    chart: {
      defaultLocale: "tr",
      locales: [tr],
      type: this.grafikTuru,
    },
    colors:['#5FB3F6'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '2%',
        endingShape: 'rounded',
        borderRadius: 2,

      },
    },
    grid:{
      show:false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 3,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: 'Gelir Tablosu'
      }
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
          return this.$helper.newTutarStr(val)
        }
      }
    }
  }

  donutChartOptions = {
    title: {
      title: 'Gelir',
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      }
    },
    colors:['#5FB3F6'],

    chart: {
      defaultLocale: "tr",
      locales: [tr],
      type: 'donut',
      offsetY: 50,
      offsetX: 0,
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: true,
              label: 'Toplam',
              formatter: (val:any) => {
                let toplam = 0;
                val.config.series.forEach((v:number) => toplam += v);
                return this.$helper.tutarStr(toplam)
              },
              fontSize:'14px',
              fontWeight: 'bold',
              color: 'grey'
            },
            value: {
              formatter: (val:number) => (this.$helper.tutarStr(val)),
              fontSize:'14px',
            }
          }
        }
      }
    },
    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }],
    labels: [],
    legend: {
      position: 'bottom',
      offsetY: 0,
      offsetX: 0,
    },
    dataLabels: {
      formatter(val:number) {
        return val.toFixed(1) + '%'
      }
    },
    tooltip: {
      enabled: false
    }
  }

  get series() {
    let datas: Array<{ name: string|null, para_birimi_id: number, data: Array<Array<any>> }> = [];
    this.items.forEach((item) => {
      if (item.hesaba_eklensin_mi) {
        let paraBirimiItems = datas.find(data => data.para_birimi_id == item.para_birimi_id);
        if (paraBirimiItems) {
          let paraBirimiItemData = paraBirimiItems.data.find(data => data[0] == new Date((item.tarih as any).substr(0, 10)).getTime());
          if (paraBirimiItemData)
            paraBirimiItemData[1] += item.mutlak_tutar;
          else
            paraBirimiItems.data.push([new Date((item.tarih as any).substr(0, 10)).getTime(), item.mutlak_tutar]);
        } else {
          datas.push({
            name: item.para_birimi.isim,
            para_birimi_id: item.para_birimi_id,
            data: [[new Date((item.tarih as any).substr(0, 10)).getTime(), item.mutlak_tutar]]
          });
        }
      }
    });
    return datas;
  }

  get donutSeries() {
    let gelirler = this.items;
    let turGelirleri: Array<{ kasa_hareketi_turu: string, kasa_hareketi_turu_id: number, tutarTlToplam: number }> = [];
    gelirler.forEach(gelir => {
      let turunGeliri = turGelirleri.find((item) => item.kasa_hareketi_turu_id == gelir.kasa_hareketi_turu_id);
      let gelirTutari = gelir.para_birimi_id != ParaBirimi.TRY ? (gelir.mutlak_tutar * Number(gelir.kur)) : gelir.mutlak_tutar;
      if (turunGeliri)
        turunGeliri.tutarTlToplam += gelirTutari;
      else
        turGelirleri.push({
          kasa_hareketi_turu: gelir.kasa_hareketi_turu.isim,
          kasa_hareketi_turu_id: gelir.kasa_hareketi_turu_id,
          tutarTlToplam: gelir.mutlak_tutar
        });
    });
    let labels: Array<string> = [];
    let values: Array<number> = [];
    turGelirleri.forEach(v => {
      labels.push(v.kasa_hareketi_turu);
      values.push(v.tutarTlToplam);
    })
    this.$nextTick(() => {
      this.pastaGrafik.updateOptions({
        labels: labels
      });
    });
    return values;
  }

  mounted() {
    this.grafikTuru = 'bar';

  }


  load() {
    this.$emit('load');
  }

  hesabaEklemeDurumuChange(item: GelirGiderEntity) {
    item.hesaba_eklensin_mi = !item.hesaba_eklensin_mi;
    this.$http.put('/api/v1/kasa-hareketi/' + item.id, item);
  }
}
