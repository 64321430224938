
import {Component, Mixins, Prop} from "vue-property-decorator";
import SimpleInputMixin from "@/mixins/SimpleInputMixin.vue";
import DatabasePicker from "@/components/pickers/DatabasePicker.vue";

@Component({
  components: {DatabasePicker}
})
export default class GelirGiderTurPicker extends Mixins(SimpleInputMixin) {
  @Prop() gelirMi!: boolean | null;
  @Prop() rules!: string;

  get action() {
    if (this.gelirMi == true)
      return '/api/v1/kasa-hareketi-turu/filter?gelir=1';
    else if (this.gelirMi == false)
      return '/api/v1/kasa-hareketi-turu/filter?gelir=0';
    else
      return null;
  }
}
