
import {Component, Prop, Ref, Vue, Watch} from "vue-property-decorator";
import FormDialog from "@/components/dialogs/FormDialog.vue";
import tr from 'apexcharts/dist/locales/tr.json'
import {GelirGiderEntity} from "@/entity/GelirGiderEntity";
import GelirGiderForm from "@/components/forms/GelirGiderForm.vue";
import DeleteDialog from "@/components/dialogs/DeleteDialog.vue";
import {ParaBirimi} from "@/enum/ParaBirimi";
import GelirGiderTurList from "@/components/lists/GelirGiderTurList.vue";

@Component({
  components: {GelirGiderTurList, DeleteDialog, GelirGiderForm, FormDialog}
})
export default class GiderInfo extends Vue {
  @Prop() items!: Array<GelirGiderEntity>;
  @Ref('pastaGrafikGider') pastaGrafik!: ApexCharts;
  excelHeaders: { [headerName: string]: string  } = {};

  grafikTuru: string = 'bar';
  raporAdi: string = "Gider Tablosu";

  headers = [
    {text: 'Tarih', value: 'tarih'},
    {text: 'Tür', value: 'tur'},
    {text: 'Açıklama', value: 'aciklama'},
    {text: 'Tutar', value: 'tutar'},
    {text: 'İşlemler', value: 'actions', align: 'end', sortable: false}
  ];

  @Watch('items')
  changeItems(){
    this.headers.forEach((header) => {
      if (header.value !== 'actions') {
        this.excelHeaders[header.text] = header.value;
      }
    });
  }
  /*grafikItems = [
    {text: 'Sütun', value: 'bar'},
    {text: 'Çizgi', value: 'line'},
    {text: 'Alan', value: 'area'}
  ];*/

  chartOptions = {
    chart: {
      defaultLocale: "tr",
      locales: [tr],
      type: this.grafikTuru,
    },
    colors:['#5FB3F6'],

    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '2%',
        endingShape: 'rounded',
        borderRadius: 2,
      },
    },
    grid:{
      show:false
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 3,
    },
    xaxis: {
      type: 'datetime',
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    yaxis: {
      title: {
        text: 'Gider Tablosu'
      },
      labels: {
        formatter: (value:any) => { return  Number(value).toFixed(0)},
      },
    },
    fill: {
      opacity: 1
    },
    tooltip: {
      y: {
        formatter: (val: any) => {
          return this.$helper.newTutarStr(val)
        }
      }
    }
  }

  donutChartOptions = {
    title: {
      title: 'Gider',
      align: 'left',
      margin: 10,
      offsetX: 0,
      offsetY: 0,
      floating: false,
      style: {
        fontSize: '16px',
        fontWeight: 'bold',
        fontFamily: undefined,
        color: '#263238'
      }
    },
    chart: {
      defaultLocale: "tr",
      locales: [tr],
      type: 'pie',
      offsetY: 50,
      offsetX: 0,
    },
    colors:["#A4A4A4","#5FB3F6"],

    responsive: [{
      breakpoint: 480,
      options: {
        legend: {
          show: false
        }
      }
    }],
    labels: [],
    legend: {
      position: 'bottom',
      offsetY: 0,
      offsetX: 0,
      formatter: function(seriesName:any, opts) {
        let percentage = opts.w.globals.series[opts.seriesIndex] / opts.w.globals.series.reduce((a, b) => a + b, 0) * 100;
        return   seriesName + "<br><span style='font-size: 15px; font-weight: bold; color: rgba(93, 114, 133, 1);'>" + percentage.toFixed(1) + "%</span>";
      }
    },
    dataLabels: {
      enabled: false  // Disable data labels
    },
    tooltip: {
      enabled: false
    }
  }

  get series() {
    let datas: Array<{ name: string|null, para_birimi_id: number, data: Array<Array<any>> }> = [];
    this.items.forEach((item) => {
      if (item.hesaba_eklensin_mi) {
        let paraBirimiItems = datas.find(data => data.para_birimi_id == item.para_birimi_id);
        if (paraBirimiItems) {
          let paraBirimiItemData = paraBirimiItems.data.find(data => data[0] == new Date((item.tarih as any).substr(0, 10)).getTime());
          if (paraBirimiItemData)
            paraBirimiItemData[1] += item.mutlak_tutar;
          else
            paraBirimiItems.data.push([new Date((item.tarih as any).substr(0, 10)).getTime(), item.mutlak_tutar]);
        } else {
          datas.push({
            name: item.para_birimi.isim,
            para_birimi_id: item.para_birimi_id,
            data: [[new Date((item.tarih as any).substr(0, 10)).getTime(), item.mutlak_tutar]]
          });
        }
      }
    });
    return datas;
  }

  get donutSeries() {
    let giderler = this.items;
    let turGiderleri: Array<{ kasa_hareketi_turu: string, kasa_hareketi_turu_id: number, tutarTlToplam: number }> = [];
    giderler.forEach(gider => {
      let turunGideri = turGiderleri.find((item) => item.kasa_hareketi_turu_id == gider.kasa_hareketi_turu_id);
      let giderTutari = gider.para_birimi_id != ParaBirimi.TRY ? (gider.mutlak_tutar * Number(gider.kur)) : gider.mutlak_tutar;
      if (turunGideri)
        turunGideri.tutarTlToplam += giderTutari;
      else
        turGiderleri.push({
          kasa_hareketi_turu: gider.kasa_hareketi_turu.isim,
          kasa_hareketi_turu_id: gider.kasa_hareketi_turu_id,
          tutarTlToplam: gider.mutlak_tutar
        });
    });
    let labels: Array<string> = [];
    let values: Array<number> = [];
    turGiderleri.forEach(v => {
      labels.push(v.kasa_hareketi_turu);
      values.push(v.tutarTlToplam);
    })

    this.$nextTick(() => {
      this.pastaGrafik.updateOptions({
        labels: labels
      });
    });

    return values;
  }

  mounted() {
    this.grafikTuru = 'bar';
  }

  load() {
    this.$emit('load');
  }

  hesabaEklemeDurumuChange(item: GelirGiderEntity) {
    item.hesaba_eklensin_mi = !item.hesaba_eklensin_mi;
    this.$http.put('/api/v1/kasa-hareketi/' + item.id, item);
  }
}
