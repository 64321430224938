
import {Component, Vue} from 'vue-property-decorator'
import KasaInfo from "@/components/infos/KasaInfo.vue";
import GelirGiderTurList from "@/components/lists/GelirGiderTurList.vue";

@Component({
  components: {GelirGiderTurList, KasaInfo}
})
export default class KasaView extends Vue {

}
